import React, {
  FunctionComponent,
  ReactChild,
  useEffect,
  ReactElement, useCallback,
} from 'react';
import { cn } from '@/lib/classNames';
import { Router } from '@/middleware/i18n/i18n';
import { useClickOutside } from '@/hooks/useClickOutside';
import { DropdownArrows } from '@/components/common/DropdownArrows';
import { typography } from '@/components/ui/typography';
import { Selectors } from '@/lib/selectors';
import styles from './Dropdown.module.scss';

interface RenderContentProps {
  isActive: boolean;
}

export interface DropdownProps {
  title?: ReactChild;
  large?: boolean;
  icon?: ReactElement;
  isFullWidth?: boolean;
  renderContent: ({ isActive }: RenderContentProps) => ReactChild;
  renderTrigger?: (onClick: () => void) => ReactElement;
  className?: string;
  sendEvent?: () => void;
  isExpanded?: boolean;
  triggerTextAlign?: 'left' | 'center' | 'right';
  shouldCloseOnClickOutside?: boolean;
  triggerClassName?: string;
  triggerAlignItems?: 'flex-start' | 'center' | 'flex-end';
  alwaysSpaceBetween?: boolean;
}

export const Dropdown: FunctionComponent<DropdownProps> = ({
  title,
  large,
  icon,
  isFullWidth,
  renderContent,
  renderTrigger,
  className,
  sendEvent,
  isExpanded,
  triggerTextAlign,
  shouldCloseOnClickOutside = true,
  triggerClassName,
  triggerAlignItems,
  alwaysSpaceBetween,
}) => {
  const { ref, active, setActive } = useClickOutside(Boolean(isExpanded));
  const elementRef = shouldCloseOnClickOutside
    ? ref
    : null;

  useEffect(() => {
    const routerHandler = () => {
      setActive(Boolean(isExpanded));
    };

    Router.events.on('routeChangeStart', routerHandler);

    return () => {
      Router.events.off('routeChangeStart', routerHandler);
    };
  }, [setActive, isExpanded]);

  const toggle = useCallback(
    () => {
      setActive((isActive) => {
        if (sendEvent && !isActive) {
          sendEvent();
        }

        return !isActive;
      });
    },
    [setActive, sendEvent],
  );

  return (
    <div
      ref={elementRef}
      className={className}
    >
      {renderTrigger
        ? renderTrigger(toggle)
        : (
          <button
            onClick={toggle}
            className={cn(
              styles.trigger,
              {
                [styles.large]: large,
                [styles.fullWidth]: isFullWidth,
                [Selectors.DropdownOpened]: active,
              },
              triggerClassName,
            )}
            data-qa='dropdown-open-button'
          >
            {icon}

            <div
              data-qa='dropdow-text-and-arrow-container'
              style={{ alignItems: triggerAlignItems }}
              className={cn(styles.textAndArrowContainer, {
                [styles.alwaysSpaceBetween]: alwaysSpaceBetween,
              })}
            >
              {title && (
                <span
                  data-qa='dropdown-arrow-button'
                  className={cn(
                    styles.triggerText,
                    typography.platformH4,
                  )}
                  style={{ textAlign: triggerTextAlign }}
                >
                  {title}
                </span>
              )}

              <DropdownArrows active={active} />
            </div>
          </button>
        )}

      {renderContent({
        isActive: active,
      })}
    </div>
  );
};
