import { EnteredUserInfoField } from '@/controllers/consultation/consultation.typedefs';
import { SESSION_STORAGE_KEYS } from '@/constants/sessionStorage';

export const useClearLogOutStorageKeys = () => () => {
  sessionStorage.removeItem(
    SESSION_STORAGE_KEYS.enteredUserInfo(EnteredUserInfoField.Name),
  );
  sessionStorage.removeItem(
    SESSION_STORAGE_KEYS.enteredUserInfo(EnteredUserInfoField.Phone),
  );
  sessionStorage.removeItem(
    SESSION_STORAGE_KEYS.enteredUserInfo(EnteredUserInfoField.Email),
  );
};
